import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/debian/devfest/Devfest2024/src/layout/mdx.tsx";
import { TeamMembers } from "../../components/team/teamMembers";
export const metadata = {
  title: "Le projet"
};
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SecondarySection = makeShortcode("SecondarySection");
const layoutProps = {
  metadata,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SecondarySection mdxType="SecondarySection">
      <h2>{`Les rencontres LGBTI+ sont des événements organisés par la Fédération LGBTI+.`}</h2>
      <p>{`Depuis plus de 25 ans, la Fédération LGBTI+ regroupe deux fois par an les Centres et associations LGBTI+ nationales qui adhèrent à son projet ; unir les forces militantes, partager les expériences.`}</p>
      <br />
Les Rencontres sont des événements emblématiques, qui existent depuis près de deux décennies, jouant un rôle crucial dans la vie des communautés LGBTI+ en promouvant la défense des droits et l'inclusion des personnes lesbiennes, gays, bisexuelles, transgenres, intersexes et de toutes celles qui se reconnaissent sous la bannière de la diversité.
 
      <br /><br />
Ces Rencontres annuelles sont une occasion privilégiée de réflexion, de partage et de renforcement des liens entre les membres que sont les Centres LGBTI+ en France, et aussi des associations nationales. Le programme de cette année est riche et varié, comprenant des conférences inspirantes, des ateliers interactifs, des débats constructifs et des moments de convivialité. Les intervenant·e·s, issue·s de divers horizons, partageront leurs expériences et expertises pour mieux comprendre les enjeux actuels et futurs.
Cette journée et demie est conçue pour permettre à chaque participant·e de se ressourcer, se former et s'engager davantage pour l'égalité des droits et de la dignité. Ensemble, nous continuerons à construire un monde où chaque personne est libre d'être elle-même, sans crainte de discrimination ou de rejet.
      <br />
      <br />
      <p>{`Les 48e Rencontres de la Fédération LGBTI+, organisées du 24 au 25 août 2024, visent à atteindre plusieurs objectifs clés pour renforcer et dynamiser la communauté LGBTI+. Ces objectifs sont les suivants :`}</p>
      <h3>{`1. Promouvoir l'Inclusion et l'Égalité`}</h3>
      <p>{`Sensibilisation : Éduquer le public et les participant·e·s sur les enjeux spécifiques auxquels les personnes LGBTI+ sont confrontées, afin de promouvoir une société plus inclusive et égalitaire.
Visibilité : Augmenter la visibilité des personnes LGBTI+ dans divers domaines (culture, politique, entreprise, etc.) pour normaliser leur présence et leurs contributions.`}</p>
      <h3>{`2. Renforcer les Capacités et les Compétences Formations et Ateliers`}</h3>
      <p>{`Proposer des sessions de formation et des ateliers interactifs pour développer les compétences des militant·e·s et des membres de la communauté dans des domaines tels que la défense des droits, la communication, et la gestion de projets.
Ressourcement : Offrir un espace de ressourcement et de soutien pour les personnes LGBTI+, permettant de renforcer leur résilience et leur bien-être mental.`}</p>
      <h3>{`3. Faciliter les Échanges et le Réseautage`}</h3>
      <p>{`Rencontres : Créer des opportunités de rencontre et de réseautage entre les participant·e·s, les associations et les intervenant·e·s pour partager des expériences, des idées et des meilleures pratiques.
Partenariats : Favoriser la création de partenariats et de collaborations entre les différentes associations et organisations présentes.`}</p>
      <h3>{`4. Informer et Éduquer`}</h3>
      <p>{`Conférences et Débats : Organiser des conférences et des débats sur des sujets d’actualité et des problématiques spécifiques à la communauté LGBTI+ pour informer et éduquer les participant·e·s.
Documentation : Produire et diffuser des ressources éducatives et informatives pour prolonger l’impact des Rencontres au-delà de l’événement.`}</p>
      <h3>{`5. Soutenir l'Engagement Militant`}</h3>
      <p>{`Mobilisation : Encourager l’engagement militant et la participation active des membres de la communauté et des alliés dans la lutte pour les droits LGBTI+.
Actions : Définir des actions concrètes et des stratégies pour avancer vers l’égalité et les droits pour tous, en tenant compte des évolutions sociopolitiques.`}</p>
      <h3>{`6. Promouvoir la ville d’Angers`}</h3>
      <p>{`Événements Culturels : Proposer des événements culturels (spectacles, expositions, projections de films) pour célébrer la diversité des identités et des expressions au sein de la communauté LGBTI+ à Angers.
Festivités : Créer des moments de festivités et de convivialité pour renforcer le sentiment d'appartenance et de solidarité entre les participant·e·s.`}</p>
      <br />
Ces objectifs, centrés sur l’inclusion, l’éducation, le réseautage et le soutien, sont essentiels pour continuer à faire progresser les droits et le bien-être des personnes LGBTI+, tout en renforçant la cohésion et la force de notre communauté.
      <br /><br />
Pour rendre tout cela possible, une équipe de bénévoles sur-vitaminée s’active en coulisse.
    </SecondarySection>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      